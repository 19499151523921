<template>
  <ul>
    <li
      v-if="canViewVerticalNavMenuHeader(item)"
      class="navigation-header text-truncate"
    >
      <feather-icon icon="MoreHorizontalIcon" size="24" />
      <span>
        {{ item.header }}
      </span>
    </li>
    <span
      v-for="(element, index) in item.items"
      :key="index"
      class="navigation navigation-main"
    >
      <component
        :is="resolveMenuItemComponentInHeader(element)"
        :item="element"
      />
    </span>
  </ul>
</template>

<script>
import { canViewVerticalNavMenuHeader } from '@/services/menu/acl'
import { resolveMenuItemComponentInHeader } from '@/services/menu/utils'
import VerticalNavMenuLink from '@/components/menu/vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '@/components/menu/vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  name: 'VerticalNavMenuHeader',
  components: {
    VerticalNavMenuLink,
    VerticalNavMenuGroup
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup () {
    return {
      canViewVerticalNavMenuHeader,
      resolveMenuItemComponentInHeader
    }
  }
}
</script>
