<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="(item, idx) in items"
      :key="idx"
      :item="item"
    />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@/services/menu/utils'
import { provide, ref } from '@vue/composition-api'
import VerticalNavMenuHeader from '@/components/menu/vertical-nav-menu-header/VerticalNavMenuHeader.vue'
import VerticalNavMenuLink from '@/components/menu/vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '@/components/menu/vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup
  },

  props: {
    items: {
      type: Array,
      required: true
    }
  },

  setup () {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent
    }
  }
}
</script>
