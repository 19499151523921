<template>
  <div
    class="app-content content"
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      class="content-wrapper"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"
    >
      <slot name="breadcrumb" />
      <div class="content-body">
        <transition
          :name="routerTransition"
          mode="out-in"
        >
          <slot />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import useAppConfig from '@/services/app/useAppConfig'

export default {
  setup () {
    const { routerTransition, contentWidth } = useAppConfig()

    return {
      routerTransition, contentWidth
    }
  }
}
</script>
