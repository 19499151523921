<template>
  <div
    class="vertical-layout h-100"
    :class="[layoutClasses]"
    :data-col="isNavMenuHidden ? '1-column' : null"
  >
    <!-- Navbar -->
    <b-navbar
      :toggleable="false"
      class="header-navbar navbar navbar-shadow align-items-center"
      :class="[navbarTypeClass]"
    >
      <navbar
        :toggle-vertical-menu-active="toggleVerticalMenuActive"
        :navbar-type-class="[...navbarTypeClass, 'header-navbar navbar navbar-shadow align-items-center']"
      />
    </b-navbar>
    <!--/ Navbar -->

    <!-- Vertical Nav Menu -->
    <vertical-nav-menu
      v-if="!isNavMenuHidden"
      :is-vertical-menu-active="isVerticalMenuActive"
      :toggle-vertical-menu-active="toggleVerticalMenuActive"
    >
      <template #header="slotProps">
        <slot
          name="vertical-menu-header"
          v-bind="slotProps"
        />
      </template>
    </vertical-nav-menu>
    <!-- /Vertical Nav Menu -->

    <!-- Vertical Nav Menu Overlay -->
    <div
      class="sidenav-overlay"
      :class="overlayClasses"
      @click="isVerticalMenuActive = false"
    />
    <!-- /Vertical Nav Menu Overlay -->

    <!-- Content -->
    <transition
      :name="routerTransition"
      mode="out-in"
    >
      <layout-content-renderer-default>
        <template
          v-for="(index, name) in $scopedSlots"
          #[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </layout-content-renderer-default>
    </transition>
    <!--/ Content -->
  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import useAppConfig from '@/services/app/useAppConfig'
import { BNavbar } from 'bootstrap-vue'
import LayoutContentRendererDefault from '@/components/layout/LayoutContentRendererDefault.vue'
import VerticalNavMenu from '@/components/menu/VerticalNavMenu.vue'
import useVerticalLayout from './useVerticalLayout'
import Navbar from '@/components/layout/Navbar'

export default {
  components: {
    VerticalNavMenu,
    BNavbar,
    Navbar,
    LayoutContentRendererDefault
  },

  setup () {
    const { routerTransition, navbarType, isNavMenuHidden } = useAppConfig()

    const {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      layoutClasses,
      overlayClasses,
      resizeHandler,
      navbarTypeClass
    } = useVerticalLayout(navbarType)

    // Resize handler
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      overlayClasses,
      layoutClasses,
      navbarTypeClass,

      // App Config
      routerTransition,
      isNavMenuHidden
    }
  }
}
</script>

<style lang="scss">
@import "~@core/scss/base/themes/bordered-layout.scss";
</style>
