<template>
  <layout-vertical>
    <div slot="breadcrumb">
      <breadcrumbs />
    </div>

    <div class="pb-3">
      <router-view :key="$route.path" />
    </div>

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@/components/layout/layout-vertical/LayoutVertical.vue'
import Navbar from '@/components/layout/Navbar.vue'
import Breadcrumbs from '@/components/layout/Breadcrumbs.vue'

export default {
  components: {
    LayoutVertical,
    Breadcrumbs,
    Navbar
  }
}
</script>
