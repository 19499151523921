// TODO: rewrite to composition and move to components (VerticalNavMenuGroup)
export default {
  watch: {
    $route: {
      immediate: true,
      handler () {
        this.updateIsActive()
      }
    }
  }
}
