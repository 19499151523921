<template>
  <feather-icon
    size="21"
    :icon="currentContentWidth.icon"
    class="text-primary cursor-pointer rotate-45"
    @click="toggleContentWidth"
  />
</template>

<script>
import useAppConfig from '@/services/app/useAppConfig'
import { computed } from '@vue/composition-api'

export default {
  name: 'ContentWidthToggler',
  setup () {
    const { contentWidth } = useAppConfig()

    const contentWidthButtons = {
      full: {
        icon: 'Minimize2Icon',
        nextSkin: 'boxed'
      },
      boxed: {
        icon: 'Maximize2Icon',
        nextSkin: 'full'
      }
    }

    const currentContentWidth = computed(() => contentWidthButtons[contentWidth.value])

    const toggleContentWidth = () => {
      contentWidth.value = currentContentWidth.value.nextSkin
    }

    return { toggleContentWidth, currentContentWidth }
  }
}
</script>
