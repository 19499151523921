import { computed } from '@vue/composition-api'
import store from '@/store'

export default function useAppConfig () {
  // ------------------------------------------------
  // isVerticalMenuCollapsed
  // ------------------------------------------------
  const isVerticalMenuCollapsed = computed({
    get: () => store.state.verticalMenu.isVerticalMenuCollapsed,
    set: val => {
      store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', val)
    }
  })

  // ------------------------------------------------
  // routerTransition
  // ------------------------------------------------
  const routerTransition = computed({
    get: () => store.state.appConfig.layout.routerTransition,
    set: val => {
      store.commit('appConfig/UPDATE_ROUTER_TRANSITION', val)
    }
  })

  // *===============================================---*
  // *--------- LAYOUT ---------------------------------------*
  // *===============================================---*

  // ------------------------------------------------
  // Content Width (Full/Boxed)
  // ------------------------------------------------
  const contentWidth = computed({
    get: () => store.state.appConfig.layout.contentWidth,
    set: val => {
      store.commit('appConfig/UPDATE_CONTENT_WIDTH', val)
    }
  })

  // ------------------------------------------------
  // isNavMenuHidden
  // ------------------------------------------------
  const isNavMenuHidden = computed({
    get: () => store.state.appConfig.layout.menu.hidden,
    set: val => {
      store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', val)
    }
  })

  // *===============================================---*
  // *--------- NAVBAR ---------------------------------------*
  // *===============================================---*
  const navbarType = computed({
    get: () => store.state.appConfig.layout.navbar.type,
    set: val => {
      store.commit('appConfig/UPDATE_NAVBAR_CONFIG', { type: val })
    }
  })

  return {
    isVerticalMenuCollapsed,
    routerTransition,

    // Navbar
    navbarType,

    // Layout
    contentWidth,
    isNavMenuHidden
  }
}
