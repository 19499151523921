import { isEmpty, isUndefined } from 'lodash'
import { useResource } from '@/services/resources'

const canShow = (item) => {
  const { meta: { resources } = {} } = item
  if (isEmpty(resources)) return true

  const permissions = resources.map(resource => {
    const { can } = useResource(resource)
    return can
  })

  // If at least one permission from array === true - show menu item
  return permissions.includes(true)
}

/**
 * Check if user can view item based on it's ability
 * Based on item's action and resource
 * @param {Object} item navigation object item
 */
export const canViewVerticalNavMenuLink = item => canShow(item)

/**
 * Check if user can view item based on it's ability
 * Based on item's action and resource & Hide group if all of it's children are hidden
 * @param {Object} item navigation object item
 */
// eslint-disable-next-line arrow-body-style
export const canViewVerticalNavMenuGroup = item => {
  const hasAnyVisibleChild = item.children.some(i => canShow(i))

  return hasAnyVisibleChild
}

/**
 * Check if user can view item based on it's ability
 * Based on item's action and resource
 * @param {Object} item navigation object item
 */
export const canViewVerticalNavMenuHeader = item => {

  const accessibleChild = item.items.map(item => {
    if (isUndefined(item.children)) return canShow(item)
    return item.children.some(i => canShow(i))
  })

  return accessibleChild.includes(true)
}
