import { menuTypes } from '@/services/menu/utils'
import { path } from '@/router'

export default [
  {
    title: 'Home',
    icon: 'HomeIcon',
    name: path.home.name
  },
  {
    header: 'Users',
    items: [
      {
        type: menuTypes.collapse,
        title: 'Players',
        icon: 'UserIcon',
        children: [
          {
            ...path.players,
            title: 'List'
          },
          {
            ...path.drops,
            title: 'Drops'
          },
          {
            ...path.withdrawals,
            title: 'Withdrawals'
          }
        ]
      },
      {
        type: menuTypes.collapse,
        title: 'Provably Fair',
        icon: 'TargetIcon',
        children: [
          {
            ...path.serverSeeds,
            title: 'Server Seeds'
          },
          {
            ...path.clientSeeds,
            title: 'Client Seeds'
          },
          {
            ...path.rolls,
            title: 'Rolls'
          }
        ]
      }
    ]
  },
  {
    header: 'Game Mechanics',
    items: [
      {
        type: menuTypes.collapse,
        title: 'Cases',
        icon: 'CodesandboxIcon',
        children: [
          {
            ...path.cases,
            title: 'List'
          },
          {
            ...path.sections,
            title: 'Sections'
          },
          {
            ...path.caseOpens,
            title: 'Opens list'
          }
        ]
      },
      {
        type: menuTypes.link,
        icon: 'TriangleIcon',
        title: 'Upgrades List',
        ...path.upgrades
      },
      {
        type: menuTypes.link,
        icon: 'RefreshCwIcon',
        title: 'Exchanges List',
        ...path.exchanges
      },
      {
        type: menuTypes.collapse,
        title: 'Lucky rolls',
        icon: 'AwardIcon',
        children: [
          {
            ...path.luckyRolls,
            title: 'List'
          },
          {
            ...path.luckyRollsWinners,
            title: 'Winners'
          }
        ]
      },
      {
        type: menuTypes.collapse,
        title: 'Welcome offers',
        icon: 'CheckCircleIcon',
        children: [
          {
            ...path.welcomeOffers,
            title: 'List'
          },
          {
            ...path.welcomeOffersUsed,
            title: 'Usage List'
          }
        ]
      },
      {
        type: menuTypes.link,
        icon: 'CrosshairIcon',
        title: 'Missions',
        ...path.missions
      }
    ]
  },
  {
    header: 'Finances',
    items: [
      {
        type: menuTypes.collapse,
        title: 'Finance',
        icon: 'CreditCardIcon',
        children: [
          {
            ...path.payments,
            title: 'Payments'
          },
          {
            ...path.balanceChanges,
            title: 'Balance Changes'
          },
          {
            ...path.balanceChangeRequests,
            title: 'Balance Change Requests'
          }
        ]
      },
      {
        type: menuTypes.collapse,
        title: 'Antifraud',
        icon: 'ShieldIcon',
        children: [
          {
            ...path.antifraudRecords,
            title: 'Players'
          },
          {
            ...path.antifraudCredentials,
            title: 'Credentials'
          },
          {
            ...path.antifraudActivations,
            title: 'Activations'
          }
        ]
      },
      {
        type: menuTypes.collapse,
        title: 'Payments systems',
        icon: 'CreditCardIcon',
        children: [
          {
            ...path.paymentSystems,
            title: 'Gateway Settings'
          },
          {
            ...path.paymentCountries,
            title: 'Gateway Countries'
          }
        ]
      },
      {
        type: menuTypes.collapse,
        title: 'Gift Cards',
        icon: 'GiftIcon',
        children: [
          {
            ...path.giftCards,
            title: 'List'
          },
          {
            ...path.giftCardsKeysDetails,
            title: 'Keys details'
          }
        ]
      }
    ]
  },
  {
    header: 'Marketing',
    items: [
      {
        type: menuTypes.collapse,
        title: 'Promocodes',
        icon: 'ScissorsIcon',
        children: [
          {
            ...path.promocodes,
            title: 'List'
          },
          {
            ...path.promocodeUsages,
            title: 'Usages List'
          }
        ]
      }
    ]
  },
  {
    header: 'Site Content',
    items: [
      {
        type: menuTypes.collapse,
        title: 'Bonuses',
        icon: 'PercentIcon',
        children: [
          {
            ...path.moneyBonuses,
            title: 'Money Bonuses'
          },
          {
            ...path.refillBonuses,
            title: 'Refill Bonuses'
          },
          {
            ...path.bonusUsages,
            title: 'Usages List'
          }
        ]
      },
      {
        type: menuTypes.collapse,
        title: 'Skins',
        icon: 'ShoppingBagIcon',
        children: [
          {
            ...path.items,
            title: 'Items'
          },
          {
            ...path.categories,
            title: 'Categories'
          },
          {
            ...path.importSkins,
            title: 'Import Skins'
          }
        ]
      },
      {
        type: menuTypes.collapse,
        title: 'Texts',
        icon: 'FileTextIcon',
        children: [
          {
            ...path.faq,
            title: 'FAQ'
          },
          {
            ...path.notifications,
            title: 'Notifications'
          },
          {
            ...path.contents,
            title: 'Content Translations'
          }
        ]
      }
    ]
  },
  {
    header: 'System',
    items: [
      {
        type: menuTypes.collapse,
        title: 'Admins',
        icon: 'UsersIcon',
        children: [
          {
            ...path.admins,
            title: 'Admins'
          },
          {
            ...path.roles,
            title: 'Roles'
          },
          {
            ...path.logs,
            title: 'Logs'
          }
        ]
      },
      {
        type: menuTypes.collapse,
        title: 'Settings',
        icon: 'ToolIcon',
        children: [
          {
            ...path.systemSettings,
            title: 'Global settings'
          },
          {
            ...path.components,
            title: 'Components'
          },
          {
            ...path.tagsTable,
            title: 'Player Tags'
          },
          {
            ...path.currencies,
            title: 'Currencies'
          },
          {
            ...path.markets,
            title: 'Markets'
          },
          {
            ...path.bots,
            title: 'Market Bots'
          }
        ]
      },
      {
        type: menuTypes.collapse,
        title: 'Monitoring',
        icon: 'MonitorIcon',
        children: [
          {
            ...path.queue,
            title: 'Queue Monitoring'
          },
          {
            ...path.global,
            title: 'Global Monitoring'
          },
          {
            title: 'Grafana',
            icon: 'ExternalLinkIcon',
            href: 'https://mojamteam.grafana.net'
          },
          {
            title: 'Sentry',
            icon: 'ExternalLinkIcon',
            href: 'https://sentry.io/organizations/soaring-ocean/projects/'
          }
        ]
      }
    ]
  }
]
