<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="align-items-center flex-grow-1 d-none d-xl-flex">
      <skin-toggler class="mr-2" />
      <content-width-toggler />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ first_name }} {{ last_name }}
            </p>
            <span class="user-status">{{ title }}</span>
          </div>
        </template>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider
} from 'bootstrap-vue'
import SkinToggler from '@/components/layout/SkinToggler.vue'
import ContentWidthToggler from '@/components/layout/ContentWidthToggler'
import { useVuex } from '@vueblocks/vue-use-vuex'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    SkinToggler,
    ContentWidthToggler
  },

  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {}
    }
  },

  setup () {
    const { useActions, useState } = useVuex('user')

    return {
      ...useActions(['logout']),
      ...useState({
        first_name: ({ data }) => data?.first_name,
        last_name: ({ data }) => data?.last_name,
        title: ({ data }) => data?.role.title
      })
    }
  }
}
</script>
