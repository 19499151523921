<template>
  <div
    v-if="breadcrumb || pageTitle"
    class="mb-2 d-flex justify-content-between align-items-center"
  >
    <!-- Content Left -->
    <div>
      <h2 class="content-header-title float-left pr-1 mb-0">
        {{ pageTitle }}
      </h2>
      <div v-if="breadcrumb" class="breadcrumb-wrapper">
        <b-breadcrumb>
          <b-breadcrumb-item
            v-for="item in breadcrumb"
            :key="item.text"
            :active="item.active"
            :to="item.to"
          >
            {{ item.text }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </div>
    <!-- Content Right -->
    <div>
      <portal-target name="page-title-actions" />
    </div>
  </div>
</template>

<script>
import { BBreadcrumb, BBreadcrumbItem, BRow, BCol } from 'bootstrap-vue'
import { computed } from '@vue/composition-api'
import { useRouter } from '@/router'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol
  },
  setup () {
    const { route } = useRouter()
    const pageTitle = computed(() => route.value.meta.pageTitle)
    const breadcrumb = computed(() => route.value.meta.breadcrumb)

    return {
      pageTitle,
      breadcrumb
    }
  }
}
</script>
