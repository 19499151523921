<template>
  <feather-icon
    size="21"
    :icon="currentSkinFromMap.icon"
    class="text-primary cursor-pointer"
    @click="nextSkin"
  />
</template>

<script>
import { useSkin } from '@/services/app'

export default {
  name: 'SkinToggler',
  setup () {
    const { currentSkinFromMap, nextSkin } = useSkin()

    return { currentSkinFromMap, nextSkin }
  }
}
</script>
